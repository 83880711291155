<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="title">{{ $t('visions') }}</div>
                <div class="list">
                    <img src="@/assets/images/verons.jpg" alt="" width="1000px">
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Profile",
   components: {
    Footer,
    Header
  },
   data () {
    return {
      
    }
  },
  mounted () {
  
  },
   methods: {
   
   }
}
</script>


<style lang="less" scoped>
@import '~@/assets/css/visions';
</style>

